import store from '../../redux/store/store'
import { hide, show } from "../../redux/slice/SettingSlice";
import axios from "axios";
import { errorToast, successToast } from '../../helper/FormHelper'
import { getToken } from "../helper/SessionHelper";
import { BaseURL, UserListURL, UserCreateURL, UserShowURL, UserUpdateURL, UserDeleteURL, UserLoginHistoryListURL } from "../helper/Config";
import { setUserList, resetUserCreateUpdateFormValue, onChangeUserCreateUpdateFormInput, setUserLoginHistoryList } from '../../redux/slice/admin/UserManagementSlice';
const AxiosHeader = {
    headers: {
        "Authorization": `Bearer ${getToken()}`
    }
}


export async function UserCreateUpdateRequest(postBody, id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + UserCreateURL
        let method = 'post';
        if (id !== 0) {
            URL = BaseURL + UserUpdateURL + "/" + id;
            method = "put";
        }
        const result = await axios({
            method,
            url: URL,
            data: postBody,
            headers: AxiosHeader.headers
        });
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast("Request Successful");
            store.dispatch(resetUserCreateUpdateFormValue())
            return true;
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}

export async function GetUserList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + UserListURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setUserList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function DeleteUser(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + UserDeleteURL + "/" + id;
        const result = await axios.delete(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            successToast(result.data.message)
            return true
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}

export async function FillUserFormRequest(id) {
    try {
        store.dispatch(show())
        let URL = BaseURL + UserShowURL + "/" + id;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            let formValue = result.data.data
            store.dispatch(onChangeUserCreateUpdateFormInput({ name: "name", value: formValue.name }));
            store.dispatch(onChangeUserCreateUpdateFormInput({ name: "email", value: formValue.email }));
            store.dispatch(onChangeUserCreateUpdateFormInput({ name: "status", value: formValue.status ? formValue.status : "" }));
            store.dispatch(onChangeUserCreateUpdateFormInput({ name: 'password', value: formValue.password ? formValue.password : "" }))
            store.dispatch(onChangeUserCreateUpdateFormInput({ name: 'role_id', value: formValue.roles[0].id }))
            return true;
        } else {
            errorToast("Request Fail ! Try Again")
            return false;
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
        return false
    }
}

export async function GetUserLoginHistoryList() {
    try {
        store.dispatch(show())
        let URL = BaseURL + UserLoginHistoryListURL;
        const result = await axios.get(URL, AxiosHeader)
        store.dispatch(hide())
        if (result.data.status === true) {
            store.dispatch(setUserLoginHistoryList(result.data.data))
        }
        if (result.data.status === false) {
            errorToast(result.data.message)
        }
    } catch (e) {
        errorToast("Something Went Wrong")
        store.dispatch(hide())
    }
}