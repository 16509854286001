import { Fragment } from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';

import FullscreenLoader from './components/MasterLayout/FullscreenLoader';

import LoginPage from './admin/pages/User/LoginPage';
import DashboardPage from './admin/pages/Dashboard/DashboardPage';
import Notfound from './admin/components/NotFound/NotFound';
import UserRoleCreateUpdatePage from './admin/pages/RolePermission/UserRoleCreateUpdatePage';
import UserRoleListPage from './admin/pages/RolePermission/UserRoleListPage';
import UserListPage from './admin/pages/UserManagement/UserListPage';
import UserCreateUpdatePage from './admin/pages/UserManagement/UserCreateUpdatePage';
import UserLoginHistoryListPage from './admin/pages/UserManagement/UserLoginHistoryListPage';
import BankCreatePage from './admin/pages/Bank/BankCreatePage';
import BankListPage from './admin/pages/Bank/BankListPage';
import SupplierCreatePage from './admin/pages/Supplier/SupplierCreatePage';
import SupplierListPage from './admin/pages/Supplier/SupplierListPage';
import WarehouseCreatePage from './admin/pages/Warehouse/WarehouseCreatePage';
import WarehouseListPage from './admin/pages/Warehouse/WarehouseListPage';
import MeasurementCreatePage from './admin/pages/Measruement/MeasurementCreatePage';
import MeasurementListPage from './admin/pages/Measruement/MeasurementListPage';
import CategoryCreatePage from './admin/pages/Category/CategoryCreatePage';
import CategoryListPage from './admin/pages/Category/CategoryListPage';
import SubCategoryCreatePage from './admin/pages/SubCategory/SubCategoryCreatePage';
import SubCategoryListPage from './admin/pages/SubCategory/SubCategoryListPage';
import ProductCreatePage from './admin/pages/Product/ProductCreatePage';
import ProductListPage from './admin/pages/Product/ProductListPage';
import PurchaseOrderCreatePage from './admin/pages/Purchase/PurchaseOrderCreatePage';
import PurchaseOrderListPage from './admin/pages/Purchase/PurchaseOrderListPage';
import PurchaseOrderViewPage from './admin/pages/Purchase/PurchaseOrderViewPage';
import PurchaseOrderRecievePage from './admin/pages/Purchase/PurchaseOrderRecievePage';
import SalesOrderCreatePage from './admin/pages/Sales/SalesOrderCreatePage';
import SalesOrderListPage from './admin/pages/Sales/SalesOrderListPage';
import SalesOrderViewPage from './admin/pages/Sales/SalesOrderViewPage';
import TransactionListPage from './admin/pages/Transaction/TransactionListPage';
import CashoutCreatePage from './admin/pages/Transaction/CashoutCreatePage';
import CustomerListPage from './admin/pages/Customer/CustomerListPage';
import CustomerViewPage from './admin/pages/Customer/CustomerViewPage';
import SalesOrderReturnPage from './admin/pages/Sales/SalesOrderReturnPage';
import CreditListPage from './admin/pages/Transaction/CreditListPage';

function App() {

      return(
        <Fragment>
      
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<LoginPage/>} />

            <Route exact path="/login" element={<LoginPage/>} />
            <Route exact path="/dashboard" element={<DashboardPage />} />
            <Route exact path="/user/list" element={<UserListPage />} />
            <Route exact path="/user/create" element={<UserCreateUpdatePage />} />
            <Route exact path="/user/role/list" element={<UserRoleListPage />} />
            <Route exact path="/user/role/create" element={<UserRoleCreateUpdatePage />} />
            <Route exact path="/user/login/history" element={<UserLoginHistoryListPage />} />
            <Route exact path="/bank/create" element={<BankCreatePage/>} />
            <Route exact path="/bank/list" element={<BankListPage/>} />
            <Route exact path="/supplier/create" element={<SupplierCreatePage/>} />
            <Route exact path="/supplier/list" element={<SupplierListPage/>} />
            <Route exact path="/warehouse/create" element={<WarehouseCreatePage/>} />
            <Route exact path="/warehouse/list" element={<WarehouseListPage/>} />
            <Route exact path="/measurement/create" element={<MeasurementCreatePage/>} />
            <Route exact path="/measurement/list" element={<MeasurementListPage/>} />
            <Route exact path="/category/create" element={<CategoryCreatePage/>} />
            <Route exact path="/category/list" element={<CategoryListPage/>} />
            <Route exact path="/subcategory/create" element={<SubCategoryCreatePage/>} />
            <Route exact path="/subcategory/list" element={<SubCategoryListPage/>} />
            <Route exact path="/product/create" element={<ProductCreatePage/>} />
            <Route exact path="/product/list" element={<ProductListPage/>} />
            <Route exact path="/purchase/order/create" element={<PurchaseOrderCreatePage/>} />
            <Route exact path="/purchase/order/list" element={<PurchaseOrderListPage/>} />
            <Route exact path="/purchase/order/:id" element={<PurchaseOrderViewPage/>} />
            <Route exact path="/purchase/order/recieve" element={<PurchaseOrderRecievePage/>} />
            <Route exact path="/sales/order/create" element={<SalesOrderCreatePage/>} />
            <Route exact path="/sales/order/list" element={<SalesOrderListPage/>} />
            <Route exact path="/sales/order/:id" element={<SalesOrderViewPage/>} />
            <Route exact path="/sales/order/return/:id/:customerId" element={<SalesOrderReturnPage/>} />
            <Route exact path="/transaction/list" element={<TransactionListPage/>} />
            <Route exact path="/transaction/credit/create" element={<CashoutCreatePage/>} />
            <Route exact path="/credit/list" element={<CreditListPage/>} />
            <Route exact path="/customer/list" element={<CustomerListPage/>} />
            <Route exact path="/customer/:id" element={<CustomerViewPage/>} />
            
            <Route path="*" element={<Notfound/>}/>
          </Routes>
        </BrowserRouter>
        <FullscreenLoader/>
        <ToastContainer />
      </Fragment>
      )
    
}

export default App;
