import React, { Fragment, useEffect, useState } from "react";
import { AiFillSave } from "react-icons/ai";
import Breadcumb from "../Common/Breadcumb";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import store from "../../../redux/store/store";
import { AiFillPlusSquare } from "react-icons/ai";
import {  Modal } from 'react-bootstrap';

import Select from 'react-select';
import {
  onChangeSalesFormInput,resetSalesFormValue
} from "../../../redux/slice/admin/SalesOrderSlice";
import {
  GetProductList
} from "../../apiServices/ProductApiService";

import {getUserDetails} from '../../helper/SessionHelper'

import { AiOutlineDelete} from "react-icons/ai";
import { GetBankList } from "../../apiServices/BankApiService";

import { GetCustomerTypeList,SalesOrderCreateApiService,GetSalesNoSeries,GetCustomerNoSeries,CustomerOrderCreateApiService,GetCustomerList } from "../../apiServices/SalesOrderApiService";
const SalesOrderCreate = () => {
  let navigate = useNavigate();
  let formValue = useSelector((state) => state.salesOrderSlice.formValue);



  let [productRowsInlineTable, setProductRowsInlineTable] = useState([]);
  let [paymentRowsInlineTable, setPaymentRowsInlineTable] = useState([]);
  let [measurement, setMeasurement] = useState({});
  let [customerPreviousPayable, setCustomerPreviousPayable] = useState(0);
  let [customerListFromApi, setCustomerListFromApi] = useState([]);

  useEffect(() => {
    
    (async () => {
      await GetProductList();
      await GetBankList();
      await GetSalesNoSeries();
      await GetCustomerTypeList();
      let dataCustomer = await GetCustomerList()
      setCustomerListFromApi(dataCustomer)
      store.dispatch(onChangeSalesFormInput({
        name: "sales_reference",
        value: getUserDetails().id,
      }))
    })();
    store.dispatch(resetSalesFormValue());
    store.dispatch(onChangeSalesFormInput({name:"date",value:(new Date()).toJSON().slice(0, 10)}));

  }, []);




  let bankList=useSelector((state)=>(state.bankSlice.bankList.data));
  let productList=useSelector((state)=>(state.productSlice.productList.data));
  let noSeries=useSelector((state)=>(state.salesOrderSlice.salesNoSeries));
  let customerTypeList=useSelector((state)=>(state.salesOrderSlice.customerTypeList.data));

  const saveChange = async () => {

    let dataObject = {
      'so_no': noSeries,
      'date': formValue.date,
      'customer_id': formValue.customer_id,
      'sales_reference': formValue.sales_reference,
      'note': formValue.note,
      'discount': formValue.discount,
      'carrying_charge': formValue.carrying_charge,
      'labour_charge': formValue.labour_charge,
      'vat': formValue.vat,
      'sub_total': parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)),
      'total':Number(parseFloat((+productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))-Number(formValue.discount)+Number(formValue.carrying_charge)+Number(formValue.labour_charge)+((Number(formValue.vat)/Number(100))*parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))).toFixed(2),
      'products': productRowsInlineTable,
      'payments': paymentRowsInlineTable,
      'paid':paymentRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.paid || 0), 0),
      'due':(Number(Number(parseFloat((+productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))-Number(formValue.discount)+Number(formValue.carrying_charge)+Number(formValue.labour_charge)+((Number(formValue.vat)/Number(100))*parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))).toFixed(2))-paymentRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.paid || 0), 0)).toFixed(2)
    }
    
      if (await SalesOrderCreateApiService(dataObject)) {
        const viewSalesOrderRoute = `/sales/order/${noSeries}`
        navigate(viewSalesOrderRoute);
      }
    
  };

  const breadcumb = {
    dashboard: "Dashboard",
    menu: "Sales Order",
    link: "/purchase/order/list",
    feature: "New Sales Order",
  };





  const handleDeleteRow = (index) => {

    let data = productRowsInlineTable.filter((_, i) => i !== index)
    setProductRowsInlineTable(data);

  };
  const addNewRow = () => {
    setProductRowsInlineTable((prevRows) => [
      ...prevRows,
      {
        category_id: "",
        sub_category_id: "",
        product_id: "",
        note: "",
        box: "",
        quantity: "",
        squarefit: "",
        rate_per_squarefit: "",
        total: "",
        sft_sales: "",
        per_box_count: "",
        sft_purchase: "",
      },
    ]);
    
  };
  const handleChangeInput = (index, field, value) => {
    setProductRowsInlineTable((prevRows) =>
      prevRows.map((row, i) =>
         i === index ? { ...row, [field]: value } : row
      )
    );
  };

const handleChangeInputSquare=(index, field, value)=>{

  //handleChangeInput(index, field, value)
  let perTilesMeasure =Number(productRowsInlineTable[index].sft_sales).toFixed(2)

  let box = Number(Number(value)/(Number(perTilesMeasure)*Number(productRowsInlineTable[index].per_box_count))).toFixed(2)

  let remainder = Number(Number(value)%(Number(perTilesMeasure)*Number(productRowsInlineTable[index].per_box_count))).toFixed(2)

  let quantity =  Number(Number(remainder)/(Number(perTilesMeasure))).toFixed(2)

  console.log(Number(productRowsInlineTable[index].per_box_count)<=Number(quantity))
  console.log(Number(productRowsInlineTable[index].per_box_count),Number(quantity))

 if(Number(productRowsInlineTable[index].per_box_count)<=Number(Math.ceil(quantity))){

    handleChangeInput(index, 'box', Math.floor(Number(box)+1))
    handleChangeInput(index, 'quantity', Math.ceil(0))
    
 }else{
    handleChangeInput(index, 'box', Math.floor(Number(box)))
    handleChangeInput(index, 'quantity', Math.ceil(quantity))
 }
  

  let squarefit =Number(productRowsInlineTable[index].sft_sales)*(Number(productRowsInlineTable[index].per_box_count))*Number(Math.floor(box)) +Number(productRowsInlineTable[index].sft_sales)*Number(Math.ceil(quantity))


  
  handleChangeInput(index, field, squarefit.toFixed(2))
  

  if(productRowsInlineTable[index].rate_per_squarefit){
    let total =Number(squarefit.toFixed(2))*Number(productRowsInlineTable[index].rate_per_squarefit)
    handleChangeInput(index, 'total', total.toFixed(2))
  }

 

}
const handleChangeInputBox=(index, field, value)=>{
  handleChangeInput(index, field, value)
  let squarefit =Number(Number((productRowsInlineTable[index].sft_sales))*Number(productRowsInlineTable[index].per_box_count))*Number(value)
  if(value<=0 || isNaN(value)){
    squarefit=0
  }
  if(productRowsInlineTable[index].quantity>=0 ){
    squarefit=squarefit+ Number(Number((productRowsInlineTable[index].sft_sales))*Number(productRowsInlineTable[index].quantity))
  }

  
  handleChangeInput(index, 'squarefit', squarefit.toFixed(2))
  if(productRowsInlineTable[index].rate_per_squarefit){
    let total =Number(squarefit.toFixed(2))*Number(productRowsInlineTable[index].rate_per_squarefit)
    handleChangeInput(index, 'total', total.toFixed(2))
  }

}
const handleChangeQuantityInput=(index, field, value)=>{
  console.log(value)
  handleChangeInput(index, field, value)
  let squarefit =Number(Number((productRowsInlineTable[index].sft_sales))*Number(productRowsInlineTable[index].per_box_count))*Number(productRowsInlineTable[index].box)+Number(Number((productRowsInlineTable[index].sft_sales))*Number(value))
  if(Number(productRowsInlineTable[index].box)<=0 || isNaN(productRowsInlineTable[index].box)){
     squarefit =Number(Number(productRowsInlineTable[index].sft_sales)*Number(value))
  }
  if((Number(value)<=0 || isNaN(value)) && productRowsInlineTable[index].box>0){
    squarefit =Number(Number(productRowsInlineTable[index].sft_sales)*Number(productRowsInlineTable[index].per_box_count))*Number(productRowsInlineTable[index].box)
 }
  handleChangeInput(index, 'squarefit', squarefit.toFixed(2))

  if(Number(value)>=Number(productRowsInlineTable[index].per_box_count)){
    let res = Number(value)/Number(productRowsInlineTable[index].per_box_count)
    let remainder = Number(value)%Number(productRowsInlineTable[index].per_box_count)   
    let totalBox = Number(!isNaN(productRowsInlineTable[index].box)?productRowsInlineTable[index].box:0)+Number(res)
    handleChangeInput(index, 'box', Math.floor(totalBox))
    console.log("total box: ",totalBox)
    handleChangeInput(index, 'quantity', remainder) 
  }

  if(productRowsInlineTable[index].rate_per_squarefit){
    let total =Number(squarefit)*Number(productRowsInlineTable[index].rate_per_squarefit)
    handleChangeInput(index, 'total', total.toFixed(2))
  }
  
}
const handleChangeRateInputSales=(index, field, value)=>{
  handleChangeInput(index, field, value)
  let total =Number(value)*Number(productRowsInlineTable[index].squarefit)
    handleChangeInput(index, 'total', total.toFixed(2))
}


  
  const handleDeletePaymentRow = (index) => {

    let data = paymentRowsInlineTable.filter((_, i) => i !== index)
    setPaymentRowsInlineTable(data);

  };
  const addNewPaymentRow = () => {
    setPaymentRowsInlineTable((prevRows) => [
      ...prevRows,
      {
        date:  (new Date()).toJSON().slice(0, 10),
        payment_method: "",
        bank_id: "",
        note: "",
        paid: ""
      },
    ]);
    
  };
  const handleChangePaymentInput = (index, field, value) => {
    setPaymentRowsInlineTable((prevRows) =>
      prevRows.map((row, i) =>
        i === index ? { ...row, [field]: value } : row
      )
    );
  };

  const handleChangePaymentPaidInput=(index,field,value)=>{
    let totalPaid = paymentRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.paid || 0), 0)
    handleChangePaymentInput(index,field,value)
   
    let totalNeedToPaid = Number(Number(customerPreviousPayable.total_due)+parseFloat((+productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))-Number(formValue.discount)+Number(formValue.carrying_charge)+Number(formValue.labour_charge)+((Number(formValue.vat)/Number(100))*parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))).toFixed(2)
    handleChangePaymentInput(index,'due',Number(Number(totalNeedToPaid)-(Number(totalPaid)+Number(value))).toFixed(2))
  }

  const handleChangeCustomer=(field,value)=>{
    //setCustomerPreviousPayable(value)
    store.dispatch(
      onChangeSalesFormInput({
        name: field,
        value: value,
      })
    );
  }
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalForm, setModalForm] = useState({
    code:"",
    name: "",
    customer_type_id: "",
    phone: "",
    email: "",
    opening_due: "",
    total_due: "",
    address: "",
    reference: "",
  });

const handleClose = () => {
    setShow(false)
    setModalData("")
};
const handleShow =async () =>{
    let data = await GetCustomerNoSeries();
    if(data !==false){
      setShow(true)
      setModalData(data)
      setModalForm({code:data})
    }
    
};
const onCustomerFormChange=(field,value)=>{
  setModalForm({
    ...modalForm,
    [field]: value,
  })
}
const saveCustomer=async()=>{
  if(await CustomerOrderCreateApiService(modalForm)){
    setModalForm({
      code:"",
      name: "",
      customer_type_id: "",
      phone: "",
      email: "",
      opening_due: "",
      total_due: "",
      address: "",
      reference: "",
    })
    let dataCustomer = await GetCustomerList()
      setCustomerListFromApi(dataCustomer)
    handleClose()
  }

}
const [selectedCustomerOption, setSelectedCustomerOption] = useState(null);
const handlePoChange =async (selectedCustomer) => {
  handleChangeCustomer('customer_id',selectedCustomer.value)
  setSelectedCustomerOption(selectedCustomer);
  
};
const customerOptions = customerListFromApi?.map((item) => ({
    value: item.id,
    label: `${item.name} (${item.code}) (${item.phone})`,
  }));

  const productListOptions = productList?.filter(item => {
  
    const isProductAlreadyAdded = productRowsInlineTable.some(
      (row) => row.product_id === item.id
    );

    
    return !isProductAlreadyAdded;
  }).map((item) => ({
    value: item.id,
    label: `${item.code}`,
    category: item.category,
    subCategory: item.sub_category,
    salesPrice:item.sales_price,
    hasOfferPrice:item.has_offer_price,
    offerPrice:item.offer_price
  }));


  const [categoryState, setCategoryState] = useState([]);
  const [subCategoryState, setSubCategoryState] = useState([]);

  const handleProductChange=(index,selectedOption)=>{
    console.log(selectedOption)
    handleChangeInput(index, "product_id", selectedOption.value)
    handleChangeInput(index, "category_id", selectedOption.category.id)
    handleChangeInput(index, "sub_category_id", selectedOption.subCategory.id)
    setCategoryState((prevArray) => [...prevArray, selectedOption.category]);
    setSubCategoryState((prevArray) => [...prevArray, selectedOption.subCategory]);
    handleChangeInput(index, "sft_sales", selectedOption.subCategory.measurement.sft_sales);
    handleChangeInput(index, "per_box_count", selectedOption.subCategory.measurement.per_box_count);
    handleChangeInput(index, "sft_purchase", selectedOption.subCategory.measurement.sft_purchase);
    if(selectedOption.hasOfferPrice){
      handleChangeRateInputSales(index,'rate_per_squarefit',selectedOption.offerPrice)
    }else{
      handleChangeRateInputSales(index,'rate_per_squarefit',selectedOption.salesPrice)
    }

  }

  return (
    <Fragment>
      <div className="container-fluid mt-5">
        <Breadcumb breadcumb={breadcumb}></Breadcumb>
        <div className="row">
          <div className="col-md-12">
            <div className="card admin-card">
              <div class="card-header card-header-custom mb-2">
                <h5 class="mb-0">
                  {breadcumb.feature}{" "}
                  <span className="text-success border">{noSeries}</span>
                </h5>
              </div>
              <div className="card-body">
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Date
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control 
                          }`}
                          onChange={(e) => {
                            store.dispatch(
                              onChangeSalesFormInput({
                                name: "date",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.date}
                          type="date"
                        />
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Customer <AiFillPlusSquare className=" cursor-pointer" onClick={handleShow}/>
                      </label>

                      <div className="col-lg-9">

                      <Select
                      value={selectedCustomerOption}
                      onChange={handlePoChange}
                      options={customerOptions}
                      isSearchable={true}
                      placeholder="Choose One"
                    />
                        
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Sales Reference
                      </label>

                      <div className="col-lg-9">
                        <select
                          onChange={(e) => {
                            store.dispatch(
                              onChangeSalesFormInput({
                                name: "sales_reference",
                                value: e.target.value,
                              })
                            );
                            
                          }}
                          className={`form-control admin-form-control }`}
                        >
                          <option value="">Choose One</option>
                          
                            <option
                              key={getUserDetails().id}
                              selected={formValue.sales_reference === getUserDetails().id}
                              value={getUserDetails().id}
                            >
                              {getUserDetails().name}
                            </option>
                          
                        </select>
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Note
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control`}
                          placeholder="note"
                          onChange={(e) => {
                            store.dispatch(
                              onChangeSalesFormInput({
                                name: "note",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.note}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border mt-5">
                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive-2">
                        <table class="table line-table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-3" style={{width:"15%"}}>Product</th>
                              <th class="col-md-4">Category</th>
                              <th class="col-md-4">SubCategory</th>
                              <th class="col-md-2">Box</th>
                              <th class="col-md-2">Quantity</th>
                              <th class="col-md-2">SquareFit</th>
                              <th class="col-md-2">Rate/Sft</th>
                              <th class="col-md-2">Total</th>
                              <th class="col-md-1">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            productRowsInlineTable.map((row, index) => (
                                <tr key={index}>
                                <td>
                                <Select
                                value={productListOptions.find(option => option.value === row.product_id)}
                                onChange={(selectedOption) => handleProductChange(index,selectedOption)}
                                options={productListOptions}
                                isSearchable={true}
                                placeholder="Choose One"
                                menuPortalTarget={document.body}
                               
                              />
                                
                              </td>
                              <td>
                                <select
                                  disabled={true}
                                  className={`form-control admin-form-control`}
                                  value={row.category_id}
                                >
                                  
                                  {categoryState?.map((item) => (
                                    <option
                                      key={item.id}
                                      selected={
                                        row.category_id === item.id
                                      }
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <select
                                  disabled={true}
                                  className={`form-control admin-form-control }`}
                                  
                                  value={row.sub_category_id}
                                >
                                  
                                  {subCategoryState?.map((item) => (
                                    <option  key={item.id}
                                    selected={
                                      row.sub_category_id === item.id
                                    }  value={item.id}>{item.measurement.height} X {item.measurement.width} ({item.measurement.per_box_count} per/box)</option>
                                  ))}
                                </select>
                              </td>
                              

                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.box}
                                  type="number"
                                  onChange={(e)=>handleChangeInputBox(index,"box",parseInt(e.target.value, 10))}
                                  step="1"
                                  min="0"
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.quantity}
                                  type="number"
                                  step="1"
                                  min="0"
                                  onChange={(e)=>handleChangeQuantityInput(index,"quantity",parseInt(e.target.value, 10))}
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.squarefit}
                                  type="number"
                                  onBlur={(e)=>handleChangeInputSquare(index,"squarefit",e.target.value)}
                                  onChange={(e)=>handleChangeInput(index,"squarefit",e.target.value)}
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.rate_per_squarefit}
                                  type="number"
                                  onChange={(e)=>handleChangeRateInputSales(index,"rate_per_squarefit",e.target.value)}
                                />
                              </td>
                              <td>
                                <input
                                  className={`form-control admin-form-control`}
                                  value={row.total}
                                  type="number"
                                  onChange={(e)=>handleChangeInput(index,"total",e.target.value)}
                                  readOnly
                                />
                              </td>
                              <td>
                                <AiOutlineDelete onClick={() => handleDeleteRow(index)} size={15}  className="action-icon"/>
                              </td>
                            </tr>
                            ))
  
                        }
                            
                            <tr>
                              
                              <td colSpan={9} className="text-center" onClick={addNewRow}>
                                <button className="btn btn-sm btn-default" >Add New</button>
                              </td>
                              
                            </tr>
                           
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="table-responsive-3">
                        <table class="line-table line-table-2 table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-2">Total</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.box || 0), 0).toFixed(2))}</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.quantity || 0), 0).toFixed(2))}</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.squarefit || 0), 0).toFixed(2))}</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.rate_per_squarefit || 0), 0).toFixed(2))}</th>
                              <th class="col-md-2">{parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2))}</th>
                              
                              <th class="col-md-4"></th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Discount
                      </label>
                      
                      
                      
                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control  }`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangeSalesFormInput({
                                name: "discount",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.discount}
                          type="number"
                        />
                       
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Carrying Charge
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control }`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangeSalesFormInput({
                                name: "carrying_charge",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.carrying_charge}
                          type="number"
                        />
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                      Labour Charge
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control }`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangeSalesFormInput({
                                name: "labour_charge",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.labour_charge}
                          type="number"
                        />
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        VAT(%)(Taka:{Number((Number(formValue.vat)/Number(100))*parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2))).toFixed(2)})
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control`}
                          placeholder="0"
                          onChange={(e) => {
                            store.dispatch(
                              onChangeSalesFormInput({
                                name: "vat",
                                value: e.target.value,
                              })
                            );
                          }}
                          value={formValue.vat}
                          type="number"
                          
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                      Sub Total
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control }`}
                          placeholder="0"
                          
                          value={parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2))}
                          type="number"
                          readOnly
                        />
                        
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Total
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control`}
                          placeholder="0"
                          readOnly
                          value={Number(parseFloat((+productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))-Number(formValue.discount)+Number(formValue.carrying_charge)+Number(formValue.labour_charge)+((Number(formValue.vat)/Number(100))*parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))).toFixed(2)}
                          type="number"
                          
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border mt-5">
                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive-2">
                        <table class="table line-table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4">Payment Method</th>
                              <th class="col-md-4">Bank</th>
                              <th class="col-md-2">Date</th>
                              <th class="col-md-2">Paid</th>

                              <th class="col-md-2">Note</th>
                              <th class="col-md-2">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            paymentRowsInlineTable.map((row, index) => (

                              <tr key={index}>
                                <td>
                                  <select
                                    onChange={(e) => {
                                      handleChangePaymentInput(index,'payment_method',e.target.value);
                                    }}
                                    className={`form-control admin-form-control`}
                                    value={row.payment_method}
                                  >
                                    <option value="">Choose One</option>
                                    
                                      <option
                                
                                        selected={
                                          row.payment_method === "Bank"
                                        }
                                        value="Bank"
                                      >
                                        Bank
                                      </option>
                                      <option
                                
                                      selected={
                                        row.payment_method === "Cash"
                                      }
                                      value="Cash"
                                      >
                                      Cash
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  <select
                                    onChange={(e) => {
                                      handleChangePaymentInput(index,'bank_id',e.target.value);
                                    }}
                                    className={`form-control admin-form-control }`}
                                    value={row.id} disabled={row.payment_method === 'Cash'}
                                  >
                                    <option value="">Choose One</option>
                                    {bankList?.map((item) => (
                                      <option
                                        key={item.id}
                                        selected={
                                          row.bank_id === item.id
                                        }
                                        value={item.id}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                <input
                                className={`form-control admin-form-control`}
                                onChange={(e) => {
                                  handleChangePaymentInput(index,"date",e.target.value)
                                }}
                                value={row.date}
                                type="date"
                              />
                                </td>

                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    onChange={(e) => {
                                      handleChangePaymentPaidInput(index,"paid",e.target.value)
                                    }}
                                    value={row.paid}
                                    type="number"
                                  />
                                </td>
                                

                                <td>
                                  <input
                                    className={`form-control admin-form-control`}
                                    onChange={(e) => {
                                      handleChangePaymentInput(index,"note",e.target.value)
                                    }}
                                    value={row.note}
                                    type="text"
                                  />
                                </td>
                            
                                <td>
                                  <AiOutlineDelete onClick={() => handleDeletePaymentRow(index)} size={15}  className="action-icon"/>
                                </td>
                            </tr>
                      
                            ))
                          }
                          <tr>
                              
                              <td colSpan={9} className="text-center" onClick={addNewPaymentRow}>
                                <button className="btn btn-sm btn-default" >Add New</button>
                              </td>
                              
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="table-responsive-3">
                        <table class="line-table line-table-2 table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-4"></th>
                              <th class="col-md-2">Total</th>
                              <th class="col-md-2">{paymentRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.paid || 0), 0)}</th>
                              <th class="col-md-2">Due</th>
                              
                              <th class="col-md-2">{(Number(Number(parseFloat((+productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))-Number(formValue.discount)+Number(formValue.carrying_charge)+Number(formValue.labour_charge)+((Number(formValue.vat)/Number(100))*parseFloat(productRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.total || 0), 0).toFixed(2)))).toFixed(2))-paymentRowsInlineTable.reduce((acc, obj) => acc + parseFloat(obj.paid || 0), 0)).toFixed(2)}</th>
                              <th class="col-md-2"></th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      onClick={saveChange}
                      className="btn  btn-success admin-btn "
                    >
                      <AiFillSave></AiFillSave> Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Customer <span className="text-success border">{modalData ? modalData:""}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Name
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control }`}
                          onChange={(e) => {
                            onCustomerFormChange('name',e.target.value)
                          }}
                          value={modalForm.name}
                          type="text"
                        />
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Customer Type
                      </label>

                      <div className="col-lg-9">
                        <select
                          onChange={(e) => {
                            onCustomerFormChange('customer_type_id',e.target.value)
                            
                          }}
                          className={`form-control admin-form-control }`}
                        >
                          <option value="">Choose One</option>
                          {customerTypeList?.map((item) => (
                            <option
                              key={item.id}
                              selected={modalForm.customer_type_id  === item.id}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                        
                      </div>
                    </div>
                  </div>
            </div>
            <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Phone
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control }`}
                          onChange={(e) => {
                            onCustomerFormChange('phone',e.target.value)
                          }}
                          value={modalForm.phone}
                          type="text"
                        />
                       
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Email
                      </label>

                      <div className="col-lg-9">
                            <input
                            className={`form-control admin-form-control }`}
                            onChange={(e) => {
                              onCustomerFormChange('email',e.target.value)
                            }}
                            value={modalForm.email}
                            type="text"
                          />
                      </div>
                    </div>
                  </div>
            </div>
            <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Opening Due
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control }`}
                          onChange={(e) => {
                            onCustomerFormChange('opening_due',e.target.value)
                          }}
                          value={modalForm.opening_due}
                          type="text"
                        />
                       
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Total Due
                      </label>

                      <div className="col-lg-9">
                          <input
                          className={`form-control admin-form-control }`}
                          onChange={(e) => {
                            onCustomerFormChange('total_due',e.target.value)
                          }}
                          value={modalForm.total_due}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
            </div>
            <div class="row mt-2">
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        Address
                      </label>

                      <div className="col-lg-9">
                        <input
                          className={`form-control admin-form-control }`}
                          onChange={(e) => {
                            onCustomerFormChange('address',e.target.value)
                          }}
                          value={modalForm.address}
                          type="text"
                        />
                        
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row form-row">
                      <label
                        for="name"
                        className=" col-lg-3 col-form-label text-title-field required"
                      >
                        References
                      </label>

                      <div className="col-lg-9">
                    <input
                          className={`form-control admin-form-control }`}
                          onChange={(e) => {
                            onCustomerFormChange('reference',e.target.value)
                          }}
                          value={modalForm.reference}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
            </div>
            
            <div className="row mt-4">
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      onClick={saveCustomer}
                      className="btn  btn-success admin-btn "
                    >
                      <AiFillSave></AiFillSave> Save
                    </button>
                  </div>
                </div>
        </Modal.Body>
       
      </Modal>
    </Fragment>
  );
};

export default SalesOrderCreate;
