import React, { Fragment, useRef } from 'react';
import { Accordion, Container, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { AiOutlineLogout, AiOutlineMenu, AiOutlineUser,AiOutlineDashboard ,AiOutlineAccountBook ,AiOutlineBarcode,AiTwotoneShopping ,AiOutlineShoppingCart ,AiOutlineDollar   } from 'react-icons/ai';
import { BsCircle, BsPeople } from 'react-icons/bs';
import logo from "../../assets/images/Logo.svg"
import { getUserDetails, removeSessions } from "../../helper/SessionHelper";
const MasterLayout = (props) => {
    let contentRef, sideNavRef, topNavRef = useRef();

    const MenuBarClickHandler = () => {
        let sideNav = sideNavRef;
        let content = contentRef;
        let topNav = topNavRef;
        if (sideNav.classList.contains('side-nav-open')) {
            sideNav.classList.add('side-nav-close');
            sideNav.classList.remove('side-nav-open');
            content.classList.add('content-expand');
            content.classList.remove('content');
            topNav.classList.remove('top-nav-open');
            topNav.classList.add('top-nav-close');
        } else {
            sideNav.classList.remove('side-nav-close');
            sideNav.classList.add('side-nav-open');
            content.classList.remove('content-expand');
            content.classList.add('content');
            topNav.classList.add('top-nav-open');
            topNav.classList.remove('top-nav-close');
        }
    };

    const isSidebarAccordionActive = () => {
        let urlList = [];
        sidebarItems.map((item) => {
            urlList.push(
                item.subMenu.map((subItem) => {
                    return subItem?.url;
                })
            );
        });
        return urlList.findIndex((items) =>
            items.includes(window.location.pathname)
        );
    };

    const sidebarItems = [{
            title: 'Dashboard',
            icon: <AiOutlineDashboard  className = "side-bar-item-icon" / > ,
            url: '/dashboard',
            subMenu: [],
        },
        {
            title: 'Account',
            icon: <AiOutlineAccountBook  className = "side-bar-item-icon" / > ,
            url: '/user/management',
            subMenu: [
                {
                    title: 'Bank',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/bank/list',
                    'permission':'product.index'
                },
                
                {
                    title: 'Supplier',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/supplier/list',
                    'permission':'product.index'
                },
                {
                    title: 'Customer',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/customer/list',
                    'permission':'product.index'
                }
            ],
        },
        {
            title: 'Product',
            icon: <AiOutlineBarcode  className = "side-bar-item-icon" / > ,
            url: '/user/management',
            subMenu: [
                {
                    title: 'Warehouse',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/warehouse/list',
                    'permission':'product.index'
                },
                
                {
                    title: 'Measurement',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/measurement/list',
                    'permission':'product.index'
                },
                
                {
                    title: 'Category List',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/category/list',
                    'permission':'product.index'
                }, 
                {
                    title: 'SubCategory',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/subcategory/list',
                    'permission':'product.index'
                },
                
                {
                    title: 'Product',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/product/list',
                    'permission':'product.index'
                }
            ],
        },
        {
            title: 'Purchase',
            icon: < AiTwotoneShopping  className = "side-bar-item-icon" / > ,
            url: '/purchase/order/list',
            subMenu: [{
                    title: 'New Purchase Order',
                    icon: < BsCircle size = { 16 }
                    className = "side-bar-subitem-icon" / > ,
                    url: '/purchase/order/create',
                    'permission':'product.index'
                },
                {
                    title: 'Purchase Order List',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/purchase/order/list',
                    'permission':'product.index'
                },
                {
                    title: 'Purchase Order Recive',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/purchase/order/recieve',
                    'permission':'product.index'
                }
            ],
        },
        {
            title: 'Sales',
            icon: < AiOutlineShoppingCart  className = "side-bar-item-icon" / > ,
            url: '/purchase/order/list',
            subMenu: [{
                    title: 'New Sales Order',
                    icon: < BsCircle size = { 16 }
                    className = "side-bar-subitem-icon" / > ,
                    url: '/sales/order/create',
                    'permission':'product.index'
                },
                {
                    title: 'Sales Order List',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/sales/order/list',
                    'permission':'product.index'
                }
            ],
        },
        {
            title: 'Transaction',
            icon: < AiOutlineDollar  className = "side-bar-item-icon" / > ,
            url: '/transaction/list',
            subMenu: [{
                    title: 'Transaction History',
                    icon: < BsCircle size = { 16 }
                    className = "side-bar-subitem-icon" / > ,
                    url: '/transaction/list',
                    'permission':'product.index'
                },
                {
                    title: 'Credit Create',
                    icon: < BsCircle size = { 16 }
                    className = "side-bar-subitem-icon" / > ,
                    url: '/transaction/credit/create',
                    'permission':'product.index'
                },
                {
                    title: 'Credit List',
                    icon: < BsCircle size = { 16 }
                    className = "side-bar-subitem-icon" / > ,
                    url: '/credit/list',
                    'permission':'product.index'
                },
            ],
        },
        
        {
            title: 'User Managment',
            icon: < BsPeople className = "side-bar-item-icon" / > ,
            url: '/user/management',
            subMenu: [
                {
                    title: 'User List',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/user/list',
                    'permission':'role.index'
                },
                
                {
                    title: 'Roles & Permissions',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/user/role/list',
                    'permission':'role.index'
                }, {
                    title: 'User Login History',
                    icon: ( <
                        BsCircle size = { 16 }
                        className = "side-bar-subitem-icon" / >
                    ),
                    url: '/user/login/history',
                    'permission':'role.index'
                },
            ],
        }
    ];

    const onLogout = () => {
        removeSessions();
    }
    const hasPermission = (requiredPermission) => {
        return getUserDetails()['permissions'].includes(requiredPermission);
    };
    return ( 
        <Fragment>
        <Navbar className="fixed-top px-0 " style={{backgroundColor:"#333333"}}>
          <Container fluid={true}>

            <Navbar.Brand>
              <div ref={(div) => {topNavRef = div}} className="top-nav-open">
                <h4 className="text-white m-0 p-0"><a onClick={MenuBarClickHandler}><AiOutlineMenu /></a></h4>
              </div>
            </Navbar.Brand>

            <div className="float-right h-auto d-flex align-items-center">
              <div className="user-dropdown">
                <img className="icon-nav-img icon-nav" src={getUserDetails()['photo']} alt=""/>
                <div className="user-dropdown-content ">
                  <div className="mt-4 text-center">
                    <img className="icon-nav-img" src={getUserDetails()['photo']} alt=""/>
                    <h6>{getUserDetails()['name']}</h6>
                    <hr className="user-dropdown-divider  p-0"/>
                  </div>
                  <NavLink to="/Profile" className="side-bar-item">
                    <AiOutlineUser className="side-bar-item-icon text-dark" />
                    <span className="side-bar-item-caption text-dark">Profile</span>
                  </NavLink>
                  <a onClick={onLogout}  className="side-bar-item">
                    <AiOutlineLogout className="side-bar-item-icon text-dark" />
                    <span className="side-bar-item-caption text-dark">Logout</span>
                  </a>
                </div>
              </div>
            </div>

          </Container>
        </Navbar>

        <div ref={(div) => {sideNavRef = div}} className="side-nav-open border-radius-0 card" style={{backgroundColor:"#333333"}}>
          <NavLink to="/" end className="d-flex justify-content-center">
            <img src={logo} className="logo"/>
          </NavLink>

          <Accordion defaultActiveKey={`${isSidebarAccordionActive()}`}>
            {sidebarItems.map((item, index) => {
              return item.subMenu.length !== 0 ? (
                  <Accordion.Item
                      key={index.toString()}
                      eventKey={`${index}`}
                      className="mt-2" style={{backgroundColor:"#333333"}}
                  >
                    <Accordion.Header style={{backgroundColor:"#333333"}}>
                      <div className="side-bar-item">
                        {item.icon}
                        <span className="side-bar-item-caption">
                      {item.title}
                    </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body style={{backgroundColor:"#333333"}}>
                      {item.subMenu.map((subItem, index) => (
                        hasPermission(subItem.permission) && <NavLink
                              key={index.toString()}
                              className={(navData) =>
                                  navData.isActive
                                      ? 'side-bar-subitem-active side-bar-subitem '
                                      : 'side-bar-subitem'
                              }
                              to={subItem?.url}
                              end
                          >
                            {subItem?.icon}
                            <span className="side-bar-subitem-caption">
                        {subItem?.title}
                      </span>
                          </NavLink>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
              ) : (
                  <NavLink
                      className={(navData) =>
                          navData.isActive
                              ? 'side-bar-item-active side-bar-item mt-2'
                              : 'side-bar-item mt-2'
                      }
                      to={item.url}
                      end
                  >
                    {item.icon}
                    <span className="side-bar-item-caption">
                  {item.title}
                </span>
                  </NavLink>
              );
            })}
          </Accordion>
        </div>


        <div ref={(div) => (contentRef = div)} className="content" style={{backgroundColor:"#EEF1F5",height:"100vh",marginTop:"0px"}}>
          <div className='pt-5'>
          {props.children}
          </div>
        </div>
      </Fragment>
    );
};

export default MasterLayout;