import { createSlice } from '@reduxjs/toolkit'

export const UserManagementSlice = createSlice({
    name: 'userManagement',
    initialState: {
        blogPost: [],
        formValue: {
            name: "",
            email: "",
            password: "",
            role_id: "",
            status: "active",
        },
        userList: [],
        userLoginHistoryList: [],
    },
    reducers: {

        onChangeUserCreateUpdateFormInput: (state, action) => {
            state.formValue[`${action.payload.name}`] = action.payload.value;
        },

        resetUserCreateUpdateFormValue: (state, action) => {
            Object.keys(state.formValue).forEach((i) => state.formValue[i] = "");
        },
        setUserList: (state, action) => {
            state.userList = action.payload
        },
        setUserLoginHistoryList: (state, action) => {
            state.userLoginHistoryList = action.payload
        },
    },
})

export const { onChangeUserCreateUpdateFormInput, resetUserCreateUpdateFormValue, setUserList, setUserLoginHistoryList } = UserManagementSlice.actions

export default UserManagementSlice.reducer